import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
    Switch,
    Spinner,
    useToast,
    Button,
} from "@chakra-ui/react";
import EditProductAddons from "./EditProductAddons"; // Import the new component

const EditStoreProductsTable = ({ id }) => {
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [viewProductId, setViewProductId] = useState(null); // State to manage viewed product ID
    const [viewProductDescription, setViewProductDescription] = useState(null);
    const toast = useToast();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                // https://alphacluster.digitalfields.co.za/diginu_admin/api/v1/dummy/get-products
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/dummy/get-products`,
                    { store_id: id }
                );
                if (response.data.status === "success") {
                    setProducts(response.data.data);
                } else {
                    toast({
                        title: "Error fetching products",
                        description: response.data.message,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                toast({
                    title: "Error",
                    description: "Failed to fetch products",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchProducts();
    }, [id, toast]);

    const handleToggle = (productId, currentStatus) => {
        setProducts(
            products.map((product) =>
                product.id === productId
                    ? { ...product, activeyn: !currentStatus }
                    : product
            )
        );
    };

    const handleViewClick = (productId, description) => {
        setViewProductId(productId); // Set the viewed product ID
        setViewProductDescription(description);
    };

    const handleBackClick = () => {
        setViewProductId(null); // Reset the viewed product ID to show the products table
    };

    if (isLoading) {
        return <Spinner size="lg" />;
    }

    if (viewProductId) {
        return (
            <EditProductAddons
                productId={viewProductId}
                productDescription={viewProductDescription}
                onBack={handleBackClick}
            />
        );
    }

    return (
        <Box>
            <Table variant="simple" size="sm">
                <Thead>
                    <Tr>
                        <Th>Product ID</Th>
                        <Th>Description 1</Th>
                        <Th>Description 2</Th>
                        <Th>Post Name</Th>
                        <Th>Regular Price</Th>
                        <Th>Charge</Th>
                        <Th>Active</Th>
                        <Th>View</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {products.map((product) => (
                        <Tr key={product.id}>
                            <Td>{product.wp_product_id}</Td>
                            <Td>{product.wp_description_display1}</Td>
                            <Td>{product.wp_description_display2}</Td>
                            <Td>{product.wp_post_name}</Td>
                            <Td>{product.wp_regular_price}</Td>
                            <Td>{product.charge}</Td>
                            <Td>
                                <Switch
                                    isChecked={product.activeyn}
                                    onChange={() =>
                                        handleToggle(
                                            product.id,
                                            product.activeyn
                                        )
                                    }
                                />
                            </Td>
                            <Td>
                                <Button
                                    onClick={() =>
                                        handleViewClick(
                                            product.wp_product_id,
                                            product.wp_description_display1
                                        )
                                    }
                                >
                                    View
                                </Button>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
};

export default EditStoreProductsTable;
