import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Spinner,
    Switch,
    useToast,
    Box,
    Flex,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const EditStoreTable = ({ id }) => {
    const toast = useToast();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [storeData, setStoreData] = useState([]);

    useEffect(() => {
        const fetchStore = async () => {
            setIsLoading(true);
            try {
                // https://alphacluster.digitalfields.co.za/diginu_admin/api/v1/dummy/get-store
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/dummy/get-store`,
                    { store_id: id }
                );
                const data = {
                    id: response.data.data.id,
                    wp_store_name: response.data.data.wp_store_name,
                    public_visibleyn:
                        response.data.data.public_visibleyn || false,
                    in_operating_hours_yn:
                        response.data.data.in_operating_hours_yn || false,
                    alcoholyn: response.data.data.alcoholyn || false,
                    activeyn: response.data.data.activeyn || false,
                };
                setStoreData([data]);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching store:", error);
                setIsLoading(false);
            }
        };

        fetchStore();
    }, [id]);

    const handleToggleChange = async (index, field) => {
        const updatedData = [...storeData];
        updatedData[index][field] = !updatedData[index][field];
        setStoreData(updatedData);

        try {
            await axios.post(
                // ${process.env.REACT_APP_API_URL}/dummy/upd-store
                `https://alphacluster.digitalfields.co.za/diginu_admin/api/v1/dummy/upd-store`,
                updatedData[0]
            );
            toast({
                title: "Store updated",
                description:
                    "The store information has been successfully updated.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error("Error updating store:", error);
            toast({
                title: "Update failed",
                description: "There was an error updating the store.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <Box width="100%" maxH="600px" overflowY="auto" mr={4} boxShadow={"lg"}>
            <Flex justify="space-between" align="center" mb={4}></Flex>
            {isLoading ? (
                <Spinner />
            ) : (
                <Table variant="striped" size="sm">
                    <Thead>
                        <Tr>
                            <Th>ID</Th>
                            <Th>Store Name</Th>
                            <Th>Visible</Th>
                            <Th>Trading</Th>
                            <Th>Accepting Orders</Th>
                            <Th>Alcohol</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {storeData.map((store, index) => (
                            <Tr key={store.id}>
                                <Td>{store.id}</Td>
                                <Td>{store.wp_store_name}</Td>
                                <Td>
                                    <Switch
                                        isChecked={store.activeyn}
                                        onChange={() =>
                                            handleToggleChange(
                                                index,
                                                "activeyn"
                                            )
                                        }
                                    />
                                </Td>
                                <Td>
                                    <Switch
                                        isChecked={store.public_visibleyn}
                                        onChange={() =>
                                            handleToggleChange(
                                                index,
                                                "public_visibleyn"
                                            )
                                        }
                                    />
                                </Td>
                                <Td>
                                    <Switch
                                        isChecked={store.in_operating_hours_yn}
                                        onChange={() =>
                                            handleToggleChange(
                                                index,
                                                "in_operating_hours_yn"
                                            )
                                        }
                                    />
                                </Td>
                                <Td>
                                    <Switch
                                        isChecked={store.alcoholyn}
                                        onChange={() =>
                                            handleToggleChange(
                                                index,
                                                "alcoholyn"
                                            )
                                        }
                                    />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            )}
        </Box>
    );
};

export default EditStoreTable;
