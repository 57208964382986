import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
    Box,
    Input,
    InputGroup,
    InputRightElement,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    HStack,
    useToast,
    Spinner,
    IconButton,
} from "@chakra-ui/react";
import {
    FaCheck,
    FaTimes,
    FaEdit,
    FaSearch,
    FaTimesCircle,
} from "react-icons/fa";

const Users = () => {
    const navigate = useNavigate();
    const toast = useToast();

    const handleView = (id) => {
        console.log(`Edit button clicked for ID: ${id}`);
        navigate(`/viewUser/${id}`);
    };
    const renderBooleanIcon = (value) => {
        return value ? <FaCheck color="green" /> : <FaTimes color="red" />;
    };

    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const itemsPerPage = 8;

    const [filters, setFilters] = useState({ active_yn: null });
    const [searchTerm, setSearchTerm] = useState("");
    const [searchString, setSearchString] = useState("");

    const fetchUsers = async (page) => {
        setLoading(true);
        try {
            const response = await axios.post(
                // https://alphacluster.digitalfields.co.za/diginu_admin/api/v1/dummy/get-users
                `${process.env.REACT_APP_API_URL}/dummy/get-users`,
                {
                    searchString,
                    activeYN: filters.active_yn,
                    itemsPerPage,
                    currentPage: page,
                }
            );
            const { users, totalCount, message, description } = response.data;
            toast({
                title: message,
                description: description,
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            setUsers(users);
            setTotalCount(totalCount);
        } catch (error) {
            console.error("Error fetching users:", error);
            toast({
                title: "Failed to load users",
                description: "Technical error occurred",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers(currentPage);
    }, [currentPage, filters, searchString]);

    const handleSearch = () => {
        setCurrentPage(1);
        setSearchString(searchTerm);
    };

    const clearSearch = () => {
        setSearchTerm("");
        setSearchString("");
        fetchUsers(1);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        } else if (e.key === "Escape") {
            clearSearch();
        }
    };

    const FilterButton = ({ value, onClick }) => {
        let bgColor = "gray.300";
        if (value === true) bgColor = "green.300";
        else if (value === false) bgColor = "red.300";

        return (
            <Button
                onClick={onClick}
                backgroundColor={bgColor}
                _hover={{ backgroundColor: bgColor }}
            >
                {value === null ? "Neutral" : value.toString()}
            </Button>
        );
    };

    const handleFilterToggle = (filter) => {
        setFilters((prevFilters) => {
            let newValue = null;
            if (prevFilters[filter] === null) {
                newValue = true;
            } else if (prevFilters[filter] === true) {
                newValue = false;
            }
            return {
                ...prevFilters,
                [filter]: newValue,
            };
        });
    };

    const pagesCount = Math.ceil(totalCount / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const renderPageButtons = () => {
        const pageButtons = [];
        const maxButtons = 5;
        const startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
        const endPage = Math.min(pagesCount, startPage + maxButtons - 1);

        if (startPage > 1) {
            pageButtons.push(
                <Button
                    key={1}
                    onClick={() => handlePageChange(1)}
                    isActive={1 === currentPage}
                >
                    1
                </Button>
            );
            if (startPage > 2) {
                pageButtons.push(
                    <Button key="ellipsis-start" disabled>
                        ...
                    </Button>
                );
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageButtons.push(
                <Button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    isActive={i === currentPage}
                >
                    {i}
                </Button>
            );
        }

        if (endPage < pagesCount) {
            if (endPage < pagesCount - 1) {
                pageButtons.push(
                    <Button key="ellipsis-end" disabled>
                        ...
                    </Button>
                );
            }
            pageButtons.push(
                <Button
                    key={pagesCount}
                    onClick={() => handlePageChange(pagesCount)}
                    isActive={pagesCount === currentPage}
                >
                    {pagesCount}
                </Button>
            );
        }

        return pageButtons;
    };

    return (
        <Box overflowX="auto">
            <InputGroup mb={4} width="300px">
                <Input
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <InputRightElement>
                    {searchTerm && (
                        <IconButton
                            icon={<FaTimesCircle />}
                            size="sm"
                            onClick={clearSearch}
                            aria-label="Clear search"
                            mr={2}
                        />
                    )}
                    <IconButton
                        icon={<FaSearch />}
                        size="sm"
                        onClick={handleSearch}
                        aria-label="Search"
                    />
                </InputRightElement>
            </InputGroup>
            {loading ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100px"
                >
                    <Spinner size="xl" />
                </Box>
            ) : (
                <>
                    <Table variant="striped">
                        <Thead>
                            <Tr>
                                <Th width="100px">User ID</Th>
                                <Th width="150px">Display Name</Th>
                                <Th width="200px">Email</Th>
                                <Th width="150px">Last App Login</Th>
                                <Th width="100px">Active</Th>
                                <Th width="150px">Referrer Display Name</Th>
                                <Th width="200px">Referrer Email</Th>
                                <Th width="100px">Referrer ID</Th>
                                <Th width="100px">View</Th>
                            </Tr>
                            <Tr>
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                                <Th>
                                    <FilterButton
                                        value={filters.active_yn}
                                        onClick={() =>
                                            handleFilterToggle("active_yn")
                                        }
                                    />
                                </Th>
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {users.map((row) => (
                                <Tr key={row.parent_referral_code}>
                                    <Td>{row.wp_user_id}</Td>
                                    <Td>{row.wp_display_name}</Td>
                                    <Td>{row.wp_user_email}</Td>
                                    <Td>{row.last_login_date}</Td>
                                    <Td>{renderBooleanIcon(row.active_yn)}</Td>
                                    <Td>{row.parent_display_name}</Td>
                                    <Td>{row.parent_email}</Td>
                                    <Td>{row.parent_referral_code}</Td>
                                    <Td>
                                        <Button
                                            onClick={() =>
                                                handleView(row.wp_user_id)
                                            }
                                            leftIcon={<FaEdit />}
                                        >
                                            View
                                        </Button>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                    <HStack justifyContent="center" mt={4}>
                        <Button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </Button>
                        {renderPageButtons()}
                        <Button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === pagesCount}
                        >
                            Next
                        </Button>
                    </HStack>
                </>
            )}
        </Box>
    );
};

export default Users;
