import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
    Box,
    Input,
    InputGroup,
    InputRightElement,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    HStack,
    useToast,
    Spinner,
    IconButton,
} from "@chakra-ui/react";
import {
    FaCheck,
    FaTimes,
    FaEdit,
    FaSearch,
    FaTimesCircle,
} from "react-icons/fa";

const Stores = () => {
    const navigate = useNavigate();
    const toast = useToast();

    const handleEdit = (id) => {
        console.log(`Edit button clicked for ID: ${id}`);
        navigate(`/editStore/${id}`);
    };
    const renderBooleanIcon = (value) => {
        return value ? <FaCheck color="green" /> : <FaTimes color="red" />;
    };

    const [stores, setStores] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const itemsPerPage = 8;

    const [filters, setFilters] = useState({
        not_visible: null,
        not_trading: null,
        not_accepting_orders: null,
    });
    const [searchTerm, setSearchTerm] = useState("");
    const [searchString, setSearchString] = useState("");

    const fetchStores = async (page) => {
        setLoading(true);
        try {
            // https://alphacluster.digitalfields.co.za/diginu_admin/api/v1/dummy/get-stores
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/dummy/get-stores`,
                {
                    searchString,
                    activeYN: filters.not_visible,
                    visibleYN: filters.not_trading,
                    openYN: filters.not_accepting_orders,
                    itemsPerPage,
                    currentPage: page,
                }
            );
            const { stores, totalCount, message, description } = response.data;
            toast({
                title: message,
                description: description,
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            setStores(stores);
            setTotalCount(totalCount);
        } catch (error) {
            console.error("Error fetching stores:", error);
            toast({
                title: "Failed to load stores",
                description: "Technical error occurred",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchStores(currentPage);
    }, [currentPage, filters, searchString]);

    const handleSearch = () => {
        setSearchString(searchTerm);
    };

    const clearSearch = () => {
        setSearchTerm("");
        setSearchString("");
        fetchStores(1);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        } else if (e.key === "Escape") {
            clearSearch();
        }
    };

    const FilterButton = ({ value, onClick }) => {
        let bgColors = ["gray.300", "green.300", "red.300"];
        let currentBgColor = 0;
        if (value === true) currentBgColor = 1;
        else if (value === false) currentBgColor = 2;

        return (
            <Button
                onClick={onClick}
                backgroundColor={bgColors[currentBgColor]}
                _hover={
                    value === false
                        ? { backgroundColor: bgColors[0] }
                        : { backgroundColor: bgColors[currentBgColor + 1] }
                }
            >
                {value === true ? (
                    <FaCheck />
                ) : value === false ? (
                    <FaTimes />
                ) : (
                    "-"
                )}
            </Button>
        );
    };

    const handleFilterToggle = (filter) => {
        setFilters((prevFilters) => {
            let newValue = null;
            if (prevFilters[filter] === null) {
                newValue = true;
            } else if (prevFilters[filter] === true) {
                newValue = false;
            }
            return {
                ...prevFilters,
                [filter]: newValue,
            };
        });

        // setFilters((prevFilters) => {
        //     let newValue = null;
        //     if (prevFilters[filter] === null) {
        //         newValue = true;
        //     } else if (prevFilters[filter] === true) {
        //         newValue = false;
        //     }
        //     return {
        //         ...prevFilters,
        //         [filter]: newValue,
        //     };
        // });
    };

    const pagesCount = Math.ceil(totalCount / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const renderPageButtons = () => {
        const pageButtons = [];
        const maxButtons = 5;
        const startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
        const endPage = Math.min(pagesCount, startPage + maxButtons - 1);

        if (startPage > 1) {
            pageButtons.push(
                <Button
                    key={1}
                    onClick={() => handlePageChange(1)}
                    isActive={1 === currentPage}
                >
                    1
                </Button>
            );
            if (startPage > 2) {
                pageButtons.push(
                    <Button key="ellipsis-start" disabled>
                        ...
                    </Button>
                );
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageButtons.push(
                <Button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    isActive={i === currentPage}
                >
                    {i}
                </Button>
            );
        }

        if (endPage < pagesCount) {
            if (endPage < pagesCount - 1) {
                pageButtons.push(
                    <Button key="ellipsis-end" disabled>
                        ...
                    </Button>
                );
            }
            pageButtons.push(
                <Button
                    key={pagesCount}
                    onClick={() => handlePageChange(pagesCount)}
                    isActive={pagesCount === currentPage}
                >
                    {pagesCount}
                </Button>
            );
        }

        return pageButtons;
    };

    return (
        <Box overflowX="auto">
            <InputGroup mb={4} width="300px">
                <Input
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <InputRightElement>
                    {searchTerm && (
                        <IconButton
                            icon={<FaTimesCircle />}
                            size="sm"
                            onClick={clearSearch}
                            aria-label="Clear search"
                            mr={2}
                        />
                    )}
                    <IconButton
                        icon={<FaSearch />}
                        size="sm"
                        onClick={handleSearch}
                        aria-label="Search"
                    />
                </InputRightElement>
            </InputGroup>
            {loading ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100px"
                >
                    <Spinner size="xl" />
                </Box>
            ) : (
                <>
                    <Table variant="striped">
                        <Thead>
                            <Tr>
                                <Th width="50px">ID</Th>
                                <Th width="100px">user_id</Th>
                                <Th width="150px">store_name</Th>
                                <Th width="100px">store_province</Th>
                                <Th width="100px">store_city</Th>
                                <Th width="50px">MM</Th>
                                <Th width="100px">visible</Th>
                                <Th width="100px">trading</Th>
                                <Th width="100px">accepting orders</Th>
                                {/* <Th width="100px">visible_yn</Th>
                                <Th width="100px">open_yn</Th>
                                <Th width="100px">active_yn</Th> */}
                                <Th width="100px">Edit</Th>
                            </Tr>
                            <Tr>
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                                <Th></Th>
                                <Th>
                                    <FilterButton
                                        value={filters.not_visible}
                                        onClick={() =>
                                            handleFilterToggle("not_visible")
                                        }
                                    />
                                </Th>
                                <Th>
                                    <FilterButton
                                        value={filters.not_trading}
                                        onClick={() =>
                                            handleFilterToggle("not_trading")
                                        }
                                    />
                                </Th>
                                <Th>
                                    <FilterButton
                                        value={filters.not_accepting_orders}
                                        onClick={() =>
                                            handleFilterToggle(
                                                "not_accepting_orders"
                                            )
                                        }
                                    />
                                </Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {stores.map((row) => (
                                <Tr key={row.id}>
                                    <Td>{row.id}</Td>
                                    <Td>{row.user_id}</Td>
                                    <Td>{row.store_name}</Td>
                                    <Td>{row.store_province}</Td>
                                    <Td>{row.store_city}</Td>
                                    <Td>{row.mm}</Td>
                                    <Td>{renderBooleanIcon(row.active_yn)}</Td>
                                    <Td>{renderBooleanIcon(row.visible_yn)}</Td>
                                    <Td>{renderBooleanIcon(row.open_yn)}</Td>
                                    <Td>
                                        <Button
                                            onClick={() => handleEdit(row.id)}
                                            leftIcon={<FaEdit />}
                                            variant="outline"
                                            colorScheme="white"
                                            background="white"
                                        >
                                            Edit
                                        </Button>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                    <HStack justifyContent="center" mt={4}>
                        <Button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </Button>
                        {renderPageButtons()}
                        <Button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === pagesCount}
                        >
                            Next
                        </Button>
                    </HStack>
                </>
            )}
        </Box>
    );
};

export default Stores;
